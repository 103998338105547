import { css } from '@emotion/core'
import React from 'react'
import { MyPostingsMenuLink } from '~/components/app/Header/MyPostingsMenuLink'
import { useMeQuery } from '~/generated/graphql'
import { HamburgerMenu } from './HamburgerMenu'
import { LogoLink } from './LogoLink'
import { ProfileMenuLink } from './ProfileMenuLink'
import { SavedJobsMenuLink } from './SavedJobsMenuLink'
import { FindJobsMenuLink } from './FindJobsMenuLink'

const navStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
`
const navContainerStyles = css`
  display: flex;
  align-items: center;
  > * {
    margin-left: 5px;
  }
`
const logoStyles = css`
  width: 90px;
`

interface Props {
  isReducedNav?: boolean
  hasSuperPostFeature?: boolean
}

export const MobileHeader = ({ isReducedNav, hasSuperPostFeature = false, ...rest }: Props) => {
  const { data: meData } = useMeQuery({ fetchPolicy: 'cache-only' })
  const currentUser = meData?.me
  const showFindJobsLink = !isReducedNav
  const showMyPostingsLink = !isReducedNav && currentUser?.loggedInRealm === 'employer'
  const showSavedJobsLink = !isReducedNav && currentUser?.loggedInRealm === 'jobseeker'

  return (
    <div
      css={css`
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
      `}
      {...rest}
    >
      <div css={navStyles}>
        <LogoLink imageCss={logoStyles} />
        <div css={navContainerStyles}>
          {showFindJobsLink ? (
            <FindJobsMenuLink hideTextAt="all" currentUser={currentUser} />
          ) : null}

          {showMyPostingsLink ? (
            <MyPostingsMenuLink hideTextAt="all" currentUser={currentUser} />
          ) : null}

          {showSavedJobsLink ? (
            <SavedJobsMenuLink hideTextAt="all" currentUser={currentUser} />
          ) : null}

          <ProfileMenuLink hideTextAt="all" />

          <div
            css={css`
              margin-left: 20px;
            `}
          >
            <HamburgerMenu hasSuperPostFeature={hasSuperPostFeature} />
          </div>
        </div>
      </div>
    </div>
  )
}
