import React from 'react'
import Link from 'next/link'
import Icon from '../../common/Icon'
import { css } from '@emotion/core'
import links from '~/lib/links'
import { screenSizeType, mqSizeMap } from '~/constants/theme.styles'
import { AnchorButton } from '~/components/common/Button/Button'
import { JoblistUser } from '~/generated/graphql'

// i18n support
import { searchNav } from '~/lang/langFile'
import { useLang } from '~/context/LangContext'

interface Props {
  hideTextAt?: screenSizeType
  currentUser?: JoblistUser | null
}

export const FindJobsMenuLink: React.FC<Props> = ({ hideTextAt, currentUser }) => {
  const { lang } = useLang()

  return (
    <Link href={links.search()} passHref>
      <AnchorButton
        color="white"
        rounded
        extraCss={[buttonStyles, hideTextAt ? roundedButtonStyles(hideTextAt) : null]}
      >
        <div
          css={css`
            position: relative;
            display: inline-block;
          `}
        >
          <Icon
            icon="searchNew"
            color="black"
            css={css`
              font-size: 18px;
              background: transparent;
              position: relative;
              top: -1px;
            `}
          />
        </div>
        <span css={[buttonText, hideTextAt ? hideTextAtStyles(hideTextAt) : null]}>
          {searchNav(lang)[0]}
        </span>
      </AnchorButton>
    </Link>
  )
}

const buttonStyles = css`
  padding: 10px 13px;
`

const buttonText = css`
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
`

const roundedButtonStyles = (screenSize: screenSizeType) => {
  return css`
    @media ${mqSizeMap[screenSize]} {
      width: 39px;
      height: 39px;
      padding: 0;
    }
  `
}

const hideTextAtStyles = (screenSize: screenSizeType) => {
  return css`
    @media ${mqSizeMap[screenSize]} {
      display: none;
    }
  `
}
