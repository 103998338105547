import React, { useState, useEffect } from 'react'
import Button from '../../common/Button'
import { css } from '@emotion/core'
import { slide as Menu } from 'react-burger-menu'
import { MAIN_FONT_FAMILY, WHITE } from '~/constants/theme.styles'
import Link from 'next/link'
import links, { superPostUrl } from '../../../lib/links'
import { getCategoryRoute } from '../../domain/blog/blogUtils'
import { Mixpanel } from '~/lib/mixpanel'

import { postJobUrl } from '~/lib/links'
import { useFlags } from '~/context/FlagsContext'
import { useMeQuery } from '~/generated/graphql'

// i18n support
import { ContactLabel, HomeLabel, LogInLabel, searchNav } from '~/lang/langFile'
import { useLang } from '~/context/LangContext'

const wrapperStyles = css`
  .bm-menu-wrap {
    position: fixed;
    background-color: ${WHITE};
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .bm-menu {
    background-color: ${WHITE};
    width: 70vw;
    height: 100vh;
  }

  .bm-overlay {
    top: 0;
    left: 0;
  }
`

const hamburgerMenuStyles = css`
  background: transparent;
  padding: 0;

  :hover,
  :focus,
  :active {
    background: transparent;
  }
`
const lineStyles = css`
  display: block;
  height: 3px;
  background: #000000;
  width: 30px;
  margin-top: 5px;
`

const mobileMenuStyles = css`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 62px 28px 42px 28px;
  margin: 0;
  outline: none;

  li {
    padding: 0 0 1.4em;
    font-size: 1.6em;
    a {
      font-weight: 900;
      font-family: ${MAIN_FONT_FAMILY};
      font-size: 2.2rem;
    }
    &:last-child {
      padding: 0;
    }
  }
`

export const HamburgerMenu = ({ hasSuperPostFeature }: { hasSuperPostFeature?: boolean }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { data: meData } = useMeQuery({ fetchPolicy: 'cache-only' })
  const currentUser = meData?.me

  const flags = useFlags()
  const { lang } = useLang()

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('noscroll')
      Mixpanel.track('Mobile Menu', {})
    } else {
      document.body.classList.remove('noscroll')
    }
    return () => {
      document.body.classList.remove('noscroll')
    }
  }, [isMenuOpen])

  const toggleMenu = () => {
    setIsMenuOpen(isOpen => !isOpen)
  }
  return (
    <div css={wrapperStyles}>
      <Button onClick={toggleMenu} unstyled extraCss={hamburgerMenuStyles}>
        <span css={lineStyles} />
        <span css={lineStyles} />
        <span css={lineStyles} />
      </Button>

      <Menu
        isOpen={isMenuOpen}
        onStateChange={({ isOpen }) => {
          setIsMenuOpen(isOpen)
        }}
        width={'70vw'}
        customBurgerIcon={false}
        customCrossIcon={false}
      >
        <ul css={mobileMenuStyles}>
          <li>
            <Link prefetch={false} href={links.home()} passHref>
              <a onClick={toggleMenu}>{HomeLabel(lang)[0]}</a>
            </Link>
          </li>
          {lang == 'en' ? (
            <>
              <li>
                <Link prefetch={false} href={links.about()} passHref>
                  <a onClick={toggleMenu}>About</a>
                </Link>
              </li>
            </>
          ) : null}
          <li>
            <Link
              prefetch={false}
              href={{
                pathname: '/category',
                query: { categorySlug: 'all' }
              }}
              as={getCategoryRoute('all')}
              passHref
            >
              <a onClick={toggleMenu}>Blog</a>
            </Link>
          </li>
          <li>
            <Link prefetch={false} href={links.faq()} passHref>
              <a onClick={toggleMenu}>FAQ</a>
            </Link>
          </li>
          <li>
            <Link prefetch={false} href={links.contact()} passHref>
              <a onClick={toggleMenu}>{ContactLabel(lang)[0]}</a>
            </Link>
          </li>
          {flags.showPostJobLink && lang == 'en' ? (
            <>
              <li>
                <Link
                  prefetch={false}
                  href={hasSuperPostFeature ? superPostUrl : postJobUrl}
                  passHref
                >
                  <a onClick={toggleMenu}>Post a Job</a>
                </Link>
              </li>
            </>
          ) : null}
          <li>
            <Link prefetch={false} href={links.search()} passHref>
              <a onClick={toggleMenu}>{searchNav(lang)[0]}</a>
            </Link>
          </li>
          {currentUser ? (
            <>
              <li>
                <Link prefetch={false} href={links.signout()} passHref>
                  <a onClick={toggleMenu}>Sign Out</a>
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link prefetch={false} href={links.login()} passHref>
                  <a onClick={toggleMenu}>{LogInLabel(lang)[0]}</a>
                </Link>
              </li>
              {lang == 'en' ? (
                <>
                  <li>
                    <Link prefetch={false} href={links.signup()} passHref>
                      <a onClick={toggleMenu}>Sign Up</a>
                    </Link>
                  </li>
                </>
              ) : null}
            </>
          )}
        </ul>
      </Menu>
    </div>
  )
}
