import React from 'react'
import Link from 'next/link'
import { css } from '@emotion/core'
import { postJobUrl, superPostUrl } from '~/lib/links'
import { AnchorButton } from '~/components/common/Button/Button'
import Icon from '../../common/Icon'

export const PostJobMenuLink = ({
  hasSuperPostFeature = false
}: {
  hasSuperPostFeature?: boolean
}) => {
  return (
    <Link href={hasSuperPostFeature ? superPostUrl : postJobUrl} passHref>
      <AnchorButton color="white" rounded extraCss={buttonStyles}>
        <div
          css={css`
            position: relative;
            display: inline-block;
          `}
        >
          <Icon
            icon="job"
            color="black"
            css={css`
              font-size: 18px;
              background: transparent;
              position: relative;
              top: -1px;
            `}
          />
        </div>
        <span css={buttonText}>Post a Job</span>
      </AnchorButton>
    </Link>
  )
}

const buttonStyles = css`
  padding: 10px 13px;
`

const buttonText = css`
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
`
